import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Consultations Gynécologiques</h4>
      <p>-Infections des voies génitales et urinaires</p>
      <p>-Douleur du bas ventre</p>
      <p>-Règles douloureuses</p>
      <p>-Kyste de l'ovaire</p>
      <p>-Fibrome</p>
      <p>-Endométriose</p>
      <p>-Periménopause</p>
      <p>-Post Ménopause</p>
      <p>-Douleurs aux Seins</p>
    </div>
  );
});

export default ModalContent;