import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { Row, Col} from 'react-materialize'
import ModalContent from "./modalContent2";
import ServiceImage from "./serviceImg2";
import { FaTimes } from "react-icons/fa";

const Modal = props => {
  let modalVeil = null;
  let modalDialog = null;
  let modalContent = null;

  const [modalTween] = useState(gsap.timeline({ paused: true }));

  useEffect(() => {
    modalTween
      .to(modalVeil, 0.25, { autoAlpha: 1 })
      .to(modalDialog, 0.35, { y: 0, autoAlpha: 1 })
      .from(
        modalContent.children,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
      )
      .from(
        "#close2", 
        0.35, 
        { y: 15, opacity: 0 }
      )
      .reverse();
  }, [modalVeil, modalDialog, modalContent, modalTween]);

  useEffect(() => {
    modalTween.reversed(!props.visible);
  }, [props.visible, modalTween]);

  const closeModal = () => {
    modalTween.reverse();
    gsap.delayedCall(modalTween.duration(), props.close);
  };

  return (
    <div className={`modal-container ${props.visible ? " show" : ""}`}>
      <div className="modal-veil" role="button" tabIndex={0} ref={e => (modalVeil = e)} onClick={closeModal} onKeyDown={closeModal}>
        <FaTimes id="close2" className="close white-text" />
      </div>
      <div className="modal-dialog color-1" ref={e => (modalDialog = e)}>
        <Row>
          <Col l={6}><ServiceImage mystyle="modal-image" /></Col>
          <Col l={6}><ModalContent ref={e => (modalContent = e)} /></Col>
        </Row>
       
      </div>
    </div>
  );
};

export default Modal;