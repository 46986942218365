import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Echographie</h4>
      <p>-Dépistage de fibrome</p>
      <p>-Dépistage de Kyste</p>
      <p>-Anomalie de l'endomètre</p>
      <p>-Hysterosonographie</p>
    </div>
  );
});

export default ModalContent;









