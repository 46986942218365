import React, { useState } from 'react';
import ServiceImage from "./serviceImg10"
import Modal from './modal10';

const Card3 = () => {
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <div className="mycards z-depth-2 color-1" onClick={showModal} onKeyDown={showModal} role="gridcell" tabIndex={0} >
      <ServiceImage mystyle="service-image" />
      <div className="service-text center">
        <h4 className="service-text-head white-text">Troubles Sexuels</h4>
        <button id="card-cta" onClick={showModal} className="center color-1-text color-0 z-depth-2">
          Plus d'info
        </button>
      </div>
      <Modal visible={modalVisible} close={closeModal} />
    </div>
  );
};

export default Card3;