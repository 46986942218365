import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Chirurgie Mineure</h4>
      <p>-Ablation des masses au niveau des Seins</p>
      <p>-Conisation</p>
      <p>-Ablation du kyste de bartholin au niveau de la vulve</p>
      <p>-Biopsie endométriale</p>
      <p>-Ablation de Polypes</p>
    </div>
  );
});

export default ModalContent;
