import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Chirurgie Majeure</h4>
      <p>-Ablation de fibromes (Myomectomie)</p>
      <p>-Ablation de kyste ovarien (Kystectomie)</p>
      <p>-Reperméabilisation tubaire (plastie tubaire)</p>
      <p>-Réparation du vagin et du périne (plastie périnéale)</p>
      <p>-Ablation de l'utérus (Hysterectomie)</p>
    </div>
  );
});

export default ModalContent;







