import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Infertilités</h4>
      <p>-Contacts Sexuels programmés</p>
      <p>-Insémination artificielle</p>
      <p>-Fécondation in vitro</p>
      <p>-ICSI</p>
      <p>-Hydrotubation des trompes bouchées</p>
      <p>-Accouchements sans douleurs</p>
    </div>
  );
});

export default ModalContent;




