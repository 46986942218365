import React, {useRef, useEffect} from "react"
import SEO from "../components/seo"
import HeaderServices from '../components/headerServices'
import {Row, Col} from 'react-materialize'
import Card1 from '../components/card1'
import Card2 from '../components/card2'
import Card3 from '../components/card3'
import Card4 from '../components/card4'
import Card5 from '../components/card5'
import Card6 from '../components/card6'
import Card7 from '../components/card7'
import Card8 from '../components/card8'
import Card9 from '../components/card9'
import Card10 from '../components/card10'
import {gsap} from 'gsap'
import { useInView } from 'react-intersection-observer'
import { Link } from "gatsby"
import Layout from "../components/layout"

const Services = () => {

  const [ref, inView] = useInView({
    rootMargin: "-100px 0px"
  })

  let row1 = [useRef(null), useRef(null), useRef(null), useRef(null)]
  let row2 = [useRef(null), useRef(null), useRef(null), useRef(null)]

  useEffect(() => {
    gsap.from(row1, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .8, stagger: 0.1})
   inView && gsap.from(row2, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .8, stagger: 0.1})
  }) 

  console.log('inView :', inView);

  return(
  <Layout>
    <SEO title="Gyn&eacute;cologie - Obst&eacute;trique - OBGYN - Sant&eacute; - Haiti - Contact" />
    <HeaderServices />
    <div className="pages-body">
      <section  className="contact-info color-4">
        <Row  >
          <Col l={3} m={6} s={12}>
            <Card1 ref={el => (row1[0] = el)} />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card2 ref={el => (row1[1] = el)} />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card3 ref={el => (row1[2] = el)} />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card4 ref={el => (row1[3] = el)} />
          </Col>
          <Row ref={ref}>
          <Col l={3} m={6} s={12}>
            <Card5 ref={el => (row2[0] = el)} />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card6 ref={el => (row2[1] = el)} />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card7 ref={el => (row2[2] = el)} />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card8 ref={el => (row2[3] = el)} />
          </Col>
          </Row>
          <Col l={3} m={6} s={12}>
            <Card9  />
          </Col>
          <Col l={3} m={6} s={12}>
            <Card10  />
          </Col>
        </Row>
        <div className="service-cta">
          <Link 
            to="/contact" 
            id="service-cta" 
            className="right color-0 color-1-text cta z-depth-2" 
            onMouseEnter={() => gsap.to("#service-cta", {scale: 1.1, ease: "power4.out"})}
            onMouseLeave={() => gsap.to("#service-cta", {scale: 1, ease: "power4.out"})}
          >
            Prenez Rendez-vous!
          </Link>
        </div>  
      </section>
    </div>
  </Layout>
)}

export default Services