import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Consultations Prénatales</h4>
      <p>-Grossesse à risque</p>
      <p>-Sonographie des 3 trimestres</p>
      <p>-Cerclage</p>
      <p>-Amniocentèse</p>
      <p>-Amino infusion</p>
      <p>-Accouchements sans douleurs</p>
    </div>
  );
});

export default ModalContent;