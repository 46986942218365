import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Dépistage de Cancer</h4>
      <p>-Seins: Mamographie, Echographie mamaire</p>
      <p>-Utérus: PAP Test, Biopsie endométriale</p>
      <p>-Ovaire: Dosage CA125, CA19-9, CA15-3, CEA</p>
    </div>
  );
});

export default ModalContent;











