import React, {useRef, useEffect} from "react"
import BackgroundImage from "./bgimageServices"
import { gsap } from 'gsap'


const HeaderServices = () =>{

  let text = [useRef(null), useRef(null)]

  useEffect(() => {
    gsap.from(text, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .2, stagger: 0.1})
  })

  return (
    <header className="header-pages center">
      <BackgroundImage className="bgimage" />
      <div className="header-pages-text">
        <h3 ref={el => text[0] = el} className="center white-text">Services</h3>
        <p ref={el => text[1] = el} className="white-text">Nous offrons une vaste gamme de services</p>
      </div>
      
    </header>
  )
}
;
export default HeaderServices;