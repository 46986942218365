import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Cryothérapie</h4>
      <p>Destruction par le froid « freezing technic » des lésions précancéreuses du col</p>
    </div>
  );
});

export default ModalContent;











