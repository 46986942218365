import React, { useState, forwardRef } from 'react';
import ServiceImage from "./serviceImg5"
import Modal from './modal5';

const Card3 = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <>
    <div ref={ref} className="mycards z-depth-2 color-1" onClick={showModal} onKeyDown={showModal} role="gridcell" tabIndex={0}>
      <ServiceImage mystyle="service-image" />
      <div className="service-text center">
        <h4 className="service-text-head white-text">Dépistage de Cancer</h4>
        <button id="card-cta" onClick={showModal} className="center color-1-text color-0 z-depth-2">
          Plus d'info
        </button>
      </div>
      
    </div>
    <Modal visible={modalVisible} close={closeModal} />
    </>
  );
});

export default Card3;