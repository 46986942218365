import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Troubles Sexuels</h4>
      <p>-Contacts douloureux (dyspareunie)</p>
      <p>-Saignement après contacts</p>
      <p>-Baisse du désir sexuel</p>
      <p>-Absence de plaisir sexuel (frigidité)</p>
    </div>
  );
});

export default ModalContent;










