import React, { forwardRef } from "react";

const ModalContent = forwardRef((props, ref) => {
  return (
    <div className="modal-content-container white-text" ref={ref}>
      <h4>Colposcopie</h4>
      <p>Evaluation au microscope des anomalies du col de l'utérus</p>
    </div>
  );
});

export default ModalContent;











